//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * docs:
 * https://panjiachen.github.io/vue-element-admin-site/feature/component/rich-editor.html#tinymce
 */
import plugins from './plugins';
import toolbar from './toolbar';
// import load from './dynamicLoadScript'
import { baseUrl, imageUrl } from '@/config';

/*
 * 不使用cdh加载的方式（牺牲一点加载速度），官方的打包工具不支持新版本的资源打包 detail see https://www.tiny.cloud/get-tiny/custom-builds/
 * 使用本地静态文件的方式引入，方便添加插件和版本升级
 */
// why use this cdn, detail see https://github.com/PanJiaChen/tinymce-all-in-one
// const tinymceCDN = 'https://cdn.jsdelivr.net/npm/tinymce-all-in-one@4.9.3/tinymce.min.js'

export default {
    name: 'Tinymce',
    props: {
        id: {
            type: String,
            default: function () {
                return (
                    'vue-tinymce-' +
                    +new Date() +
                    ((Math.random() * 1000).toFixed(0) + '')
                );
            },
        },
        value: {
            type: String,
            default: '',
        },
        toolbar: {
            type: Array,
            required: false,
            default() {
                return [];
            },
        },
        menubar: {
            type: String,
            default: 'file edit insert view format table',
        },
        height: {
            type: [Number, String],
            required: false,
            default: 360,
        },
        width: {
            type: [Number, String],
            required: false,
            default: 'auto',
        },
    },
    data() {
        return {
            hasChange: false,
            hasInit: false,
            tinymceId: this.id,
            fullscreen: false,
            languageTypeList: {
                en: 'en',
                zh: 'zh_CN',
                es: 'es_MX',
                ja: 'ja',
            },
        };
    },
    computed: {
        containerWidth() {
            const width = this.width;
            if (/^[\d]+(\.[\d]+)?$/.test(width)) {
                // matches `100`, `'100'`
                return `${width}px`;
            }
            return width;
        },
    },
    watch: {
        value(val) {
            if (!this.hasChange && this.hasInit) {
                this.$nextTick(() =>
                    window.tinymce.get(this.tinymceId).setContent(val || '')
                );
            }
        },
    },
    mounted() {
        // this.init()
        this.initTinymce();
    },
    activated() {
        if (window.tinymce) {
            this.initTinymce();
        }
    },
    deactivated() {
        this.destroyTinymce();
    },
    destroyed() {
        this.destroyTinymce();
    },
    methods: {
        /* init() {
      // dynamic load tinymce from cdn
      load(tinymceCDN, (err) => {
        if (err) {
          this.$message.error(err.message)
          return
        }
        this.initTinymce()
      })
    }, */
        initTinymce() {
            const _this = this;
            window.tinymce.init({
                selector: `#${this.tinymceId}`,
                language: this.languageTypeList['zh'],
                height: this.height,
                body_class: 'panel-body ',
                object_resizing: false,
                toolbar: this.toolbar.length > 0 ? this.toolbar : toolbar,
                menubar: this.menubar,
                plugins: plugins,
                end_container_on_empty_block: true,
                powerpaste_word_import: 'clean',
                code_dialog_height: 450,
                code_dialog_width: 1000,
                advlist_bullet_styles: 'square',
                advlist_number_styles: 'default',
                imagetools_cors_hosts: ['www.tinymce.com', 'codepen.io'],
                images_upload_url: baseUrl + "/file/addForRichTexts/",
                images_upload_base_path: imageUrl,
                icons: 'custom', // 图标主题
                // images_upload_credentials: true,
                default_link_target: '_blank',
                link_title: false,
                statusbar: false, // 隐藏底部状态栏（状态栏指的是编辑器最底下、左侧显示dom信息、右侧显示Tiny版权链接和调整大小的那一条。）
                // fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',
                nonbreaking_force_tab: true, // inserting nonbreaking space &nbsp; need Nonbreaking Space Plugin
                style_formats: [
                    {
                        title: '行高',
                        items: [
                            {
                                title: '1',
                                block: 'p',
                                styles: { 'line-height': '1.0' },
                            },
                            {
                                title: '1.5',
                                block: 'p',
                                styles: { 'line-height': '1.5' },
                            },
                            {
                                title: '1.75',
                                block: 'p',
                                styles: { 'line-height': '1.75' },
                            },
                            {
                                title: '2',
                                block: 'p',
                                styles: { 'line-height': '2' },
                            },
                            {
                                title: '3',
                                block: 'p',
                                styles: { 'line-height': '3' },
                            },
                            {
                                title: '4',
                                block: 'p',
                                styles: { 'line-height': '4' },
                            },
                            {
                                title: '5',
                                block: 'p',
                                styles: { 'line-height': '5' },
                            },
                        ],
                    },
                ],
                style_formats_merge: true,
                style_formats_autohide: true,
                init_instance_callback: editor => {
                    if (_this.value) {
                        editor.setContent(_this.value);
                    }
                    _this.hasInit = true;
                    editor.on('NodeChange Change KeyUp SetContent', () => {
                        this.hasChange = true;
                        this.$emit('input', editor.getContent());
                    });
                },
                setup(editor) {
                    editor.on('FullscreenStateChanged', e => {
                        _this.fullscreen = e.state;
                    });
                },
                convert_urls: false,
            });
        },
        destroyTinymce() {
            const tinymce = window.tinymce.get(this.tinymceId);
            if (this.fullscreen) {
                tinymce.execCommand('mceFullScreen');
            }

            if (tinymce) {
                tinymce.destroy();
            }
        },
        setContent(value) {
            window.tinymce.get(this.tinymceId).setContent(value);
        },
        getContent() {
            window.tinymce.get(this.tinymceId).getContent();
        },
        imageSuccessCBK(arr) {
            arr.forEach(v =>
                window.tinymce
                    .get(this.tinymceId)
                    .insertContent(`<img class="wscnph" src="${v.url}" >`)
            );
        },
    },
};
