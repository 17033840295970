//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "ScrollBar",
    props: {
        dragSlider: {
            type: Boolean,
            default: false,
        },
        color: {
            type: String,
            default: '#3b424933'
        }
    },
    data() {
        return {
            main: {
                height: 0,
                width: 0
            },
            view: {
                height: 0,
                width: 0,
                offsetX: 0,
                offsetY: 0
            },
            slider: {
                show: 0,
                vertical: {
                    active: false,
                    size: '0px',
                    offset: "0px"
                },
                horizontal: {
                    active: false,
                    size: '0px',
                    offset: '0px'
                }
            },
            move: {
                startX: -1,
                startY: -1,
                type: null
            }
        }
    },
    methods: {
        onScroll: function (e) {
            // 横向滚动
            let wheelX = e.wheelDeltaX;
            // 纵向滚动
            let wheelY = e.wheelDeltaY;

            if (this.slider.horizontal.active) {
                // let moveWheelX = this.view.offsetX + wheelX;
                this.setOffsetX(wheelX);
            }
            if (this.slider.vertical.active) {
                // let moveWheelY = this.view.offsetY + wheelY;
                this.setOffsetY(wheelY);
            }
        },
        setOffsetX: function (value) {
            // 计算滚动区域的left
            let max = this.main.width - this.view.width;
            this.view.offsetX += value;
            if (this.view.offsetX > 0) {
                this.view.offsetX = 0;
            }
            if (this.view.offsetX < max) {
                this.view.offsetX = max;
            }
            // 计算水平滑块的left
            let scrollWidth = this.main.width - Number(this.slider.horizontal.size.replace("px", ""));
            let percent = this.view.offsetX / max;
            this.slider.horizontal.offset = (scrollWidth * percent) + "px";
        },
        setOffsetY: function (value) {
            // 计算滚动区域的top
            let max = this.main.height - this.view.height;
            this.view.offsetY += value;
            if (this.view.offsetY > 0) {
                this.view.offsetY = 0;
            }
            if (this.view.offsetY < max) {
                this.view.offsetY = max;
            }
            // 计算垂直滑块的top
            let scrollHeight = this.main.height - Number(this.slider.vertical.size.replace("px", ""));
            let percent = this.view.offsetY / max;
            this.slider.vertical.offset = (scrollHeight * percent) + "px";
        },
        showSlider: function () {
            this.main.height = this.$refs.main.offsetHeight;
            this.main.width = this.$refs.main.offsetWidth;
            this.view.height = this.$refs.view.offsetHeight;
            this.view.width = this.$refs.view.offsetWidth;
            this.slider.vertical.active = this.view.height > this.main.height;
            this.slider.horizontal.active = this.view.width > this.main.width;
    
           
            if (Math.abs(this.view.offsetY) > (this.view.height - this.main.height)) {
                let move = Math.abs(this.view.offsetY) - (this.view.height - this.main.height)
                this.setOffsetY(move);
            }
            if (Math.abs(this.view.offsetX) > (this.view.width - this.main.width)) {
                let move = Math.abs(this.view.offsetX) - (this.view.width - this.main.width)
                this.setOffsetX(move);
            }
            
            this.slider.vertical.size = (this.main.height / this.view.height * this.main.height) + "px";
            this.slider.horizontal.size = (this.main.width / this.view.width * this.main.width) + "px";
        },
        mouseDown: function (downEvent, type) {
            if (!this.dragSlider) {
                return;
            }
            // 起始位置
            this.move.startX = downEvent.x;
            this.move.startY = downEvent.y;
            this.move.type = type;
            document.addEventListener("mousemove", this.mouseMove);
            /*document.onmousemove = (moveEvent) => {
                // 负值向右，正值向左
                let moveX = startX - moveEvent.x;
                let moveY = startY - moveEvent.y;

                //将起始位置设置为当前位置用于下次计算，不这样做会导致移动速度越来越快
                startX = moveEvent.x;
                startY = moveEvent.y;

                let viewX = this.view.width * (moveX / this.main.width);
                let viewY = this.view.height * (moveY / this.main.height);

                if (type === 'horizontal') {
                    this.setOffsetX(viewX);
                }
                if (type === 'vertical') {
                    this.setOffsetY(viewY);
                }
            }*/
            document.onmouseup = this.mouseUp;
        },
        mouseMove: function (moveEvent) {
            // 负值向右，正值向左
            let moveX = this.move.startX - moveEvent.x;
            let moveY = this.move.startY - moveEvent.y;
    
            //将起始位置设置为当前位置用于下次计算，不这样做会导致移动速度越来越快
            this.move.startX = moveEvent.x;
            this.move.startY = moveEvent.y;
    
            let viewX = this.view.width * (moveX / this.main.width);
            let viewY = this.view.height * (moveY / this.main.height);
    
            if (this.move.type === 'horizontal') {
                this.setOffsetX(viewX);
            }
            if (this.move.type === 'vertical') {
                this.setOffsetY(viewY);
            }
        },
        mouseUp: function () {
            document.removeEventListener("mousemove", this.mouseMove);
        },
        onMouseOver:function () {
            this.slider.show = 1;
            this.showSlider();
        }
    },
    mounted() {
        this.showSlider();
        window.addEventListener("resize", () => {
            this.showSlider();
        })
    },
    destroyed() {
        window.removeEventListener("resize", ()=>{});
    }
}
