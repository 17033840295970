//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "PageHeader",
    props: {
        pageName: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: 'el-icon-menu'
        },
        border: {
            type: String,
            default: ''
        }

    },
    computed: {
        borderBottom: function () {
            return `${this.borderWidth} ${this.borderColor} ${this.borderStyle}`
        }
    }
}
