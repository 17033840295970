var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"main",staticClass:"ScrollBar",on:{"wheel":_vm.onScroll,"mouseover":_vm.onMouseOver,"mouseout":function($event){_vm.slider.show = 0}}},[_c('div',{ref:"view",staticClass:"scroll-view",style:({top: _vm.view.offsetY+'px', left: _vm.view.offsetX+'px'})},[_vm._t("default")],2),(_vm.slider.vertical.active)?_c('div',{staticClass:"scroll-slider _vertical",style:({
            opacity : _vm.slider.show,
            height: _vm.slider.vertical.size,
            top: _vm.slider.vertical.offset,
            background: _vm.color
        }),on:{"mousedown":function($event){return _vm.mouseDown($event, 'vertical')}}}):_vm._e(),(_vm.slider.horizontal.active)?_c('div',{staticClass:"scroll-slider _horizontal",style:({
            opacity : _vm.slider.show,
            width: _vm.slider.horizontal.size,
            left: _vm.slider.horizontal.offset,
            background: _vm.color
        }),on:{"mousedown":function($event){return _vm.mouseDown($event, 'horizontal')}}}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }