var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-header',{attrs:{"page-name":"行政区划管理","border":"0"}}),_c('div',{staticClass:"area-main"},[_c('div',{staticStyle:{"width":"25vw"}},[_c('el-card',{staticStyle:{"height":"80vh"},attrs:{"body-style":{
                    height: '100%',
                    padding: 0,
                }}},[_c('div',{staticClass:"input-search"},[_c('el-input',{attrs:{"placeholder":"搜索","clearable":"","prefix-icon":"el-icon-search"},model:{value:(_vm.filterText),callback:function ($$v) {_vm.filterText=$$v},expression:"filterText"}})],1),_c('scroll-bar',{style:({
                        height: '90%',
                        margin: '20px',
                        width: '90%',
                    })},[_c('el-tree',{ref:"tree",staticClass:"filter-tree",attrs:{"data":_vm.areaTree,"props":_vm.defaultProps,"node-key":"code","accordion":"","indent":40,"default-expanded-keys":[530000],"filter-node-method":_vm.filterNode,"expand-on-click-node":false,"highlight-current":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var node = ref.node;
                    var data = ref.data;
return _c('div',{staticClass:"custom-tree-node"},[_c('div',{staticClass:"custom-tree-node-label"},[_vm._v(_vm._s(node.label))]),_c('div',[_c('el-button',{attrs:{"type":"text","size":"mini","icon":"el-icon-edit"},on:{"click":function () { return _vm.clickCurrentAreaItem(data); }}},[_vm._v(" 描述信息 ")])],1)])}}])})],1)],1)],1),_c('div',{staticStyle:{"width":"70vw"}},[_c('el-card',{staticStyle:{"height":"80vh"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentAreaItem.code),expression:"currentAreaItem.code"}],staticClass:"description-card-title"},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_vm._v("当前修改的行政区划为："+_vm._s(_vm.currentAreaItem.name))]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.updateCurrentAreaItem}},[_vm._v("保存修改")])],1),_c('tinymce',{ref:"tinymce",attrs:{"height":"60vh"},model:{value:(_vm.currentAreaItem.areaDescribe),callback:function ($$v) {_vm.$set(_vm.currentAreaItem, "areaDescribe", $$v)},expression:"currentAreaItem.areaDescribe"}})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }