//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Tinymce from '@/components/tinymce';
import ScrollBar from '@/components/scroller-bar/scroll-bar';
import PageHeader from '@/components/page-header';
export default {
    name: 'areaList',
    components: {
        Tinymce,
        ScrollBar,
        PageHeader,
    },
    computed: {
        areaTree() {
            return this.$store.state['areaManagement'].areaTree;
        },
    },
    inject: ['showLoading', 'hideLoading'],
    data() {
        return {
            filterText: '',
            defaultProps: {
                children: 'childrenList',
                label: 'name',
            },
            currentAreaItem: {
                id: null,
                areaDescribe: null,
            },
        };
    },
    created() {
        this.getAreaTree();
    },
    watch: {
        filterText(val) {
            this.$refs.tree.filter(val);
        },
    },
    methods: {
        filterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
        getAreaTree() {
            this.showLoading();
            this.$store
                .dispatch('areaManagement/getAreaTree')
                .then(res => {
                    if (res.code !== 200) {
                        this.$notify({
                            title: res.code,
                            message: res.msg,
                            type: 'error',
                            duration: 2000,
                            position: 'bottom-right',
                        });
                    }
                    this.hideLoading();
                })
                .catch(() => {});
        },

        // 点击当前选中的行政区划
        clickCurrentAreaItem(item) {
            this.showLoading();
            if (item && item.code) {
                // 根据行政区编码获取信息
                this.$store.dispatch('areaManagement/getAreaByCode', item.code).then(data => {
                    this.currentAreaItem = data;
                    // 不同行政区划的需要清除富文本编辑器已有的内容
                    this.$refs.tinymce.setContent(data.areaDescribe || '');
                    this.hideLoading();
                    // if (!data.areaDescribe) {
                    //     this.currentAreaItem.areaDescribe = '';
                    // }
                    // console.log(this.currentAreaItem, 'this.currentAreaItem');
                });
            }
        },

        // 修改当前选中行政区划的描述信息
        updateCurrentAreaItem() {
            this.showLoading();
            // 根据行政区编码修改描述信息
            this.$store.dispatch('areaManagement/updateDescribe', this.currentAreaItem).then(res => {
                this.$notify({
                    title: res.code,
                    message: res.msg,
                    type: res.code === 200 ? 'success' : 'error',
                    duration: 2000,
                    position: 'bottom-right',
                });
                this.hideLoading();
            });
        },
    },
};
